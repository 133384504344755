<template>
    <div class="trust">
        <div class="topPart">
            <div class="form-item">
                <div class="inputBox" style="margin-right:10px;">
                    <div class="title">产品名称</div>
                    <Input
                        v-model="params.cpmc"
                        placeholder="模糊搜索"
                        style="margin-right:50px; width:300px;"
                    />
                </div>
                <span class="title">发行日期</span>
                <DatePicker
                    :type="'date'"
                    v-model="params.fxsj[0]"
                    @on-change="getTimeStartfx"
                    format="yyyy-MM-dd"
                    placeholder="开始时间"
                    style="width: 140px; margin-right:5px;"
                ></DatePicker>至
                <DatePicker
                    :type="'date'"
                    v-model="params.fxsj[1]"
                    placeholder="结束时间"
                    @on-change="getTimeEndfx"
                    format="yyyy-MM-dd"
                    style="width: 140px;margin:0 50px 0 5px;"
                ></DatePicker>
            </div>
            <div class="form-item">
                <div class="inputBox" style="margin-right:60px;">
                    <div class="title">发行机构</div>
                    <Select v-model="params.fxjg" style="width:300px;">
                        <Option
                            v-for="item in fxjg"
                            :value="item.value"
                            :key="item.value"
                        >{{ item.label }}</Option>
                    </Select>
                </div>
                <div class="inputBox">
                    <div class="title">实际融资人</div>
                    <Input
                        v-model="params.sjrzr"
                        placeholder="模糊搜索"
                        style="margin-right:50px; width:300px;"
                    />
                </div>
            </div>
            <div class="form-item">
                <div class="title">投资方向</div>
                <Checkbox
                    :value="checktzAll"
                    @click.prevent.native="handleCheckAll('checktzAll',tzfx,'tzfx')"
                >全选</Checkbox>
                <CheckboxGroup v-model="params.tzfx">
                    <Checkbox
                        :label="item.id"
                        :key="index"
                        v-for="(item,index) in tzfx"
                    >{{item.name}}</Checkbox>
                </CheckboxGroup>
            </div>
            <div class="form-item">
                <div class="title">付息方式</div>
                <Checkbox
                    :value="checkfxAll"
                    @click.prevent.native="handleCheckAll('checkfxAll',fxfs,'fxfs')"
                >全选</Checkbox>
                <CheckboxGroup v-model="params.fxfs">
                    <Checkbox
                        :label="item.id"
                        :key="index"
                        v-for="(item,index) in fxfs"
                    >{{item.name}}</Checkbox>
                </CheckboxGroup>
            </div>
            <div class="form-item">
                <div class="title">产品期限</div>
                <Checkbox
                    :value="checkqxAll"
                    @click.prevent.native="handleCheckAll('checkqxAll',cpqx,'cpqx')"
                >全选</Checkbox>
                <CheckboxGroup v-model="params.cpqx">
                    <Checkbox
                        :label="item.id"
                        :key="index"
                        v-for="(item,index) in cpqx"
                    >{{item.name}}</Checkbox>
                </CheckboxGroup>
            </div>
            <div class="form-item">
                <div class="title">产品状态</div>
                <Checkbox
                    :value="checkztAll"
                    @click.prevent.native="handleCheckAll('checkztAll',cpzt,'cpzt')"
                >全选</Checkbox>
                <CheckboxGroup v-model="params.cpzt">
                    <Checkbox
                        :label="item.id"
                        :key="index"
                        v-for="(item,index) in cpzt"
                    >{{item.name}}</Checkbox>
                </CheckboxGroup>
            </div>
            <div class="form-item">
                <div class="title">预计年化收益</div>
                <Checkbox
                    :value="checksyAll"
                    @click.prevent.native="handleCheckAll('checksyAll',yjnhsy,'yjnhsy')"
                >全选</Checkbox>
                <CheckboxGroup v-model="params.yjnhsy">
                    <Checkbox
                        :label="item.id"
                        :key="index"
                        v-for="(item,index) in yjnhsy"
                    >{{item.name}}</Checkbox>
                </CheckboxGroup>
            </div>
            <div class="form-item">
                <div class="title">投资门槛</div>
                <Checkbox
                    :value="checkmkAll"
                    @click.prevent.native="handleCheckAll('checkmkAll',tzmk,'tzmk')"
                >全选</Checkbox>
                <CheckboxGroup v-model="params.tzmk">
                    <Checkbox
                        :label="item.id"
                        :key="index"
                        v-for="(item,index) in tzmk"
                    >{{item.name}}</Checkbox>
                </CheckboxGroup>
            </div>
            <div class="form-item">
                <div class="title">项目所在地</div>
                <Checkbox
                    :value="checkxmAll"
                    @click.prevent.native="handleCheckAll('checkxmAll',xmszd,'xmszd')"
                >全选</Checkbox>
                <CheckboxGroup v-model="params.xmszd">
                    <Checkbox
                        :label="item.id"
                        :key="index"
                        v-for="(item,index) in xmszd"
                    >{{item.name}}</Checkbox>
                </CheckboxGroup>
            </div>
            <Button
                type="info"
                style="width: 144px; display:block; margin:0 auto;"
                @click="getList"
            >查询</Button>
        </div>
        <div class="middlePart">
            <span>已选条件：</span>
            <Tag type="border" v-if="params.cpmc" size="large" @on-close="params.cpmc=''" closable>
                产品名称:
                <span class="tabContent">{{params.cpmc}}</span>
            </Tag>
            <Tag
                type="border"
                v-if="params.fxsj[0]&&params.fxsj[1]"
                size="large"
                @on-close="params.fxsj=[]"
                closable
            >
                发行日期:
                <span class="tabContent">{{params.fxsj[0]}}至{{params.fxsj[1]}}</span>
            </Tag>
            <Tag
                type="border"
                v-if="params.fxjg[0]"
                size="large"
                @on-close="params.fxjg[0]=''"
                closable
            >
                发行机构:
                <span class="tabContent">{{params.fxjg[0]}}</span>
            </Tag>
            <Tag
                type="border"
                v-if="params.sjrzr"
                size="large"
                @on-close="params.sjrzr=''"
                closable
            >
                实际融资人:
                <span class="tabContent">{{params.sjrzr}}</span>
            </Tag>
            <Tag
                type="border"
                size="large"
                v-for="(item,index) in tzfxSelect"
                :key="index"
                @on-close="delItem(params.tzfx,index)"
                closable
            >
                投资方向:
                <span class="tabContent">{{item}}</span>
            </Tag>
            <Tag
                type="border"
                size="large"
                v-for="(item,index) in fxfsSelect"
                :key="index"
                @on-close="delItem(params.fxfs,index)"
                closable
            >
                付息方式:
                <span class="tabContent">{{item}}</span>
            </Tag>
            <Tag
                type="border"
                size="large"
                v-for="(item,index) in cpqxSelect"
                :key="index"
                @on-close="delItem(params.cpqx,index)"
                closable
            >
                产品期限:
                <span class="tabContent">{{item}}</span>
            </Tag>
            <Tag
                type="border"
                size="large"
                v-for="(item,index) in cpztSelect"
                :key="index"
                @on-close="delItem(params.cpzt,index)"
                closable
            >
                产品状态:
                <span class="tabContent">{{item}}</span>
            </Tag>
            <Tag
                type="border"
                size="large"
                v-for="(item,index) in yjnhsySelect"
                :key="index"
                @on-close="delItem(params.yjnhsy,index)"
                closable
            >
                预计年化收益:
                <span class="tabContent">{{item}}</span>
            </Tag>
            <Tag
                type="border"
                size="large"
                v-for="(item,index) in tzmkSelect"
                :key="index"
                @on-close="delItem(params.tzmk,index)"
                closable
            >
                投资门槛:
                <span class="tabContent">{{item}}</span>
            </Tag>
            <Tag
                type="border"
                size="large"
                v-for="(item,index) in xmszdSelect"
                :key="index"
                @on-close="delItem(params.xmszd,index)"
                closable
            >
                项目所在地:
                <span class="tabContent">{{item}}</span>
            </Tag>
        </div>
        <div class="bottomPart">
            <Table border :loading="dataLoading"
                            tooltip-theme="light" :columns="stockColumns" :data="stockTable">
                <template slot-scope="{ row }" slot="action">
                    <Button
                        type="primary"
                        size="small"
                        style="margin-right: 5px"
                        @click.stop="tableClick(row)"
                    >查看详情</Button>
                </template>
            </Table>
            <div style="text-align: right; padding: 20px;">
                <Page :total="totalNum" @on-change="pageChange" show-elevator />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { getCpqx, getCpzt, getFxfs, getFxjg, getTzfx, getTzmk, getXmszd, getYjnhsy } from '@/api/financial/dimensionTable'
import { getMisData, getDetail, fxjg } from '@/api/financial/assetManagement'
import dataReports from '@/mixins/dataReports'

export default {
    name: 'assetManagement',
    mixins: [dataReports],
    data() {
        return {
            params: {
                cpbm: "",
                cpmc: "",
                cpqx: [],
                cpzt: [],
                current: 0,
                fxfs: [],
                fxjg: [''],
                fxsj: [],
                size: 10,
                sjrzr: "",
                tzfx: [],
                tzmk: [],
                xmszd: [],
                yjnhsy: []
            },
            cpzt: [],
            cpqx: [],
            fxfs: [],
            fxjg: [],
            tzfx: [],
            tzmk: [],
            xmszd: [],
            yjnhsy: [],
            dataLoading: false,
            stockTable: [],
            stockColumns: [
                {
                    title: '产品名称',
                    align: 'center',
                    width: '300',
                    ellipsis: true,
                    tooltip: true,

                    key: 'cpmc'
                },
                {
                    title: '产品状态',
                    align: 'center',
                    ellipsis: true,
                    tooltip: true,

                    key: 'cpztmc'
                },
                {
                    title: '发行时间',
                    ellipsis: true,
                    tooltip: true,

                    align: 'center',
                    key: 'fxsj'
                },
                {
                    title: '预计年化收益率',
                    ellipsis: true,
                    tooltip: true,

                    align: 'center',
                    key: 'yqnhsylmc'
                },
                {
                    title: '投资期限',
                    ellipsis: true,
                    tooltip: true,

                    align: 'center',
                    key: 'tzqxmc'
                },
                {
                    title: '起投金额',
                    ellipsis: true,
                    tooltip: true,

                    align: 'center',
                    key: 'qtjemc'
                },
                {
                    title: '投资方向',
                    ellipsis: true,
                    tooltip: true,

                    align: 'center',
                    key: 'tzfxmc'
                },
                {
                    title: '付息方式',
                    ellipsis: true,
                    tooltip: true,

                    align: 'center',
                    key: 'fxfsmc'
                },
                {
                    title: '发行机构',
                    ellipsis: true,
                    tooltip: true,

                    align: 'center',
                    key: 'fxjgmc'
                },
                {
                    title: '查看详情',
                    ellipsis: true,
                    tooltip: true,

                    align: 'center',
                    slot: 'action',
                }
            ],
            totalNum: 0,
        }
    },
    computed: {
        ...mapState([
            'activeDetail'
        ]),
        checktzAll() {
            return this.tzfxSelect.length == this.tzfx.length
        },
        checkfxAll() {
            return this.fxfsSelect.length == this.fxfs.length
        },
        checkqxAll() {
            return this.cpqxSelect.length == this.cpqx.length
        },
        checkztAll() {
            return this.cpztSelect.length == this.cpzt.length
        },
        checksyAll() {
            return this.yjnhsySelect.length == this.yjnhsy.length
        },
        checkmkAll() {
            return this.tzmkSelect.length == this.tzmk.length
        },
        checkxmAll() {
            return this.xmszdSelect.length == this.xmszd.length
        },
        tzfxSelect() {
            let arr = []
            this.params.tzfx.map(item => {
                this.tzfx.some(infoItem => {
                    if (infoItem.id == item) {
                        arr.push(infoItem.name);
                    }
                })
            });
            return arr;
        },
        fxfsSelect() {
            let arr = []
            this.params.fxfs.map(item => {
                this.fxfs.some(infoItem => {
                    if (infoItem.id == item) {
                        arr.push(infoItem.name);
                    }
                })
            });
            return arr;
        },
        xmszdSelect() {
            let arr = []
            this.params.xmszd.map(item => {
                this.xmszd.some(infoItem => {
                    if (infoItem.id == item) {
                        arr.push(infoItem.name);
                    }
                })
            });
            return arr;
        },
        cpqxSelect() {
            let arr = []
            this.params.cpqx.map(item => {
                this.cpqx.some(infoItem => {
                    if (infoItem.id == item) {
                        arr.push(infoItem.name);
                    }
                })
            });
            return arr;
        },
        cpztSelect() {
            let arr = []
            this.params.cpzt.map(item => {
                this.cpzt.some(infoItem => {
                    if (infoItem.id == item) {
                        arr.push(infoItem.name);
                    }
                })
            });
            return arr;
        },
        yjnhsySelect() {
            let arr = []
            this.params.yjnhsy.map(item => {
                this.yjnhsy.some(infoItem => {
                    if (infoItem.id == item) {
                        arr.push(infoItem.name);
                    }
                })
            });
            return arr;
        },
        tzmkSelect() {
            let arr = []
            this.params.tzmk.map(item => {
                this.tzmk.some(infoItem => {
                    if (infoItem.id == item) {
                        arr.push(infoItem.name);
                    }
                })
            });
            return arr;
        }
    },
    mounted() {
        this.initOptions()
        this.getList()
    },
    methods: {
        ...mapMutations(['changeState']),
        tableClick(row) {
            getDetail({
                id: row.id
            })
                .then(info => {
                    this.changeState({
                        prop: 'activeDetail',
                        value: info
                    })
                    localStorage.setItem('activeDetail', JSON.stringify(info))
                    this.$router.push({
                        path: `/Insight/financialMarket/financialProduct/trustDetail`
                    });
                })
        },
        delItem(list, index) {
            list.splice(index, 1)
        },
        initOptions() {
            getCpqx().then(data => this.cpqx = data)
            getCpzt().then(data => this.cpzt = data)
            getFxfs().then(data => this.fxfs = data)
            getFxjg().then(data => this.fxjg = data)
            getTzfx().then(data => this.tzfx = data)
            getTzmk().then(data => this.tzmk = data)
            getXmszd().then(data => this.xmszd = data)
            getYjnhsy().then(data => this.yjnhsy = data)
        },

        getTimeStartfx(value) {
            this.$set(this.params.fxsj, 0, value)
        },
        getTimeEndfx(value) {
            this.$set(this.params.fxsj, 1, value)
        },
        getList() {
            this.dataLoading = true;
            this.params.fxjg = this.params.fxjg.filter(item => item)
            this.params.fxsj = this.params.fxsj.filter(item => item)
            getMisData(this.params).then(data => {
                this.stockTable = data.records;
                this.totalNum = data.total;
            }).finally(() => {
                this.dataLoading = false;
            })
        },
        pageChange(value) {
            this.params.current = value;
            this.stockTable = [];
            this.getList()
        },
        // 全选checkbox用
        handleCheckAll(val, list, key) {
            let item = this.params;
            // 已经全选
            if (this[val]) {
                item[key] = [];
            } else {
                item[key] = [];
                item[key] = list.map(item => {
                    return item.id;
                });
            }

        },
    }
}
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";
.trust {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: #fff;
    padding: 20px;
}
.trust {
    .topPart {
        width: 100%;
        height: 460px;
        background: rgba(255, 255, 255, 1);
        border-radius: 10px;
        border: 1px solid rgba(227, 227, 229, 1);
        padding: 20px 30px;
        .form-item {
            margin-bottom: 20px;
            @include flex(flex-start);
            .inputBox {
                @include flex(flex-start);
            }
            .title {
                font-size: 14px;
                font-family: MicrosoftYaHei;
                color: rgba(102, 102, 102, 1);
                line-height: 19px;
                margin-right: 20px;
                word-break: keep-all;
            }
            .addIssue {
                position: relative;
                .seletcTypeBox {
                    position: absolute;
                    left: 0;
                    top: 20px;
                    height: 40px;
                    display: none;
                    overflow: hidden;
                    background: #fff;
                    border: 1px solid rgba(227, 227, 229, 1);
                    padding: 10px;
                    z-index: 100;
                }
                &:hover .seletcTypeBox {
                    display: block;
                }
            }
        }
    }
    .middlePart {
        margin: 20px 0;
    }
}
</style>
