import { post, get } from '@/utils/http'

export function getMisData (params) {
  return post({ url: '/service-insight/finance_trust/get_mis_data', params })
}
export function getDetail (params) {
  return get({ url: '/service-insight/finance_trust/mis_detail', params })
}
export function fxjg (params) {
  return get({ url: '/service-insight/finance_dim/fxjg', params })
}

